.card {
    width: 450px;
    margin: 1em;
    position: relative;
    justify-content: center;
    @media only screen and (max-width: 600px) {
        width: 90%;
    }
}

.media {
    width: 65% !important;
    height: 40px !important;
    padding: 2em !important;
    margin: 0 auto !important;
    @media only screen and (max-width: 600px) {
        height: 35px !important;
    }
}

.variant {
    width: 50% !important;
    height: 40px !important;
    padding: 2em !important;
    margin: 0 auto !important;
    @media only screen and (max-width: 600px) {
        height: 35px !important;
    }
}

.cardActions {
    position: absolute;
    bottom: 0;
    width: 100%;
    justify-content: space-between;
}

.stack{
    padding: 2em 0;
}

.date {
    font-family: "Noto Sans";
    padding-left: 0.5em;
    font-size: 0.8em;
}
