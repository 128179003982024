@font-face {
    font-family: MuliRegular;
    src: url(../../../assets/fonts/Muli-Regular.ttf);
}

@font-face {
    font-family: nunito;
    src: url(../../../assets/fonts/Nunito-Regular.ttf);
}

.card {
    font-family: MuliRegular, serif ;
    width: 450px;
    margin: 1em;
    position: relative;
    padding-bottom: 2em;
    @media only screen and (max-width: 600px) {
        width: 90%;
    }
}

.media {
    font-family: MuliRegular, serif ;
    width: 200px !important;
    height: 200px !important;
    padding: 1em 0 !important;
    margin: 0 auto;
    @media only screen and (max-width: 600px) {
        width: 120px !important;
        height: 120px !important;
    }
}

.variant {
    font-family: MuliRegular, serif ;
    width: 250px !important;
    height: 150px !important;
    padding: 1em 0 !important;
    margin: 1.5em auto;
    @media only screen and (max-width: 600px) {
        width: 150px !important;
        height: 100px !important;
    }
}

.cardActions {
    position: absolute;
    bottom: 0;
    right: 0;
}

.typo {
    margin: 1em 0;
}

.description {
    line-height: 1.3em;
    text-align: center;
    padding: 1em;
    font-size: 1em;
}
