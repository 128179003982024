.container {
  position: fixed;
  z-index: 10000;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  > .content {
    font-size: 0.85em;
    padding: 1em;
    border-radius: 1em;
    display: block;
    position: relative;
    text-align: center;
    justify-content: center;
    align-items: center;
    background: #eeeeee;
    width: 40%;
    height: auto;
    @media only screen and (max-width: 1280px) {
      width: 55%;
    }
    @media only screen and (max-width: 600px) {
      width: 80%;
    }
    >.cancel {
      position: absolute;
      right: 0;
      top: 0;
      margin: 1em;
      cursor: pointer;
    }
    > .title {
      font-size: 1.5em;
      padding: 2em;
    }
    > .buttonContainer{
      display: flex;
      justify-content: center;
      align-content: center;
      padding: 2em 0;
      >.button {
        margin: 1em 2em;
        border: 2px solid #8f0b98;
        background: transparent;
        color:  #8f0b98;
        width: 8em;
        height: 4em;
        cursor: pointer;
        transition: all 0.5s;
        text-decoration: none;
        &:hover {
          color:  white;
          background: #8f0b98;
        }
      }
      > .buttonCancel {
        @extend .button;
        background: #8f0b98;
        color: white;
        &:hover {
          background: transparent;
          color:  #8f0b98;
        }
      }
    }
  }
}
.hide {
  display: none;
}
.href{
  text-decoration: none;
  color: black;
  &:hover {
    color: blue
  }
}