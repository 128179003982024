.searchMenu {
  position: sticky;
  z-index: 500;
  top: 90px;
  display: flex;
  align-items: center;
  padding: 1em;
  justify-content: center;
  filter: drop-shadow(0px 5px 7px rgba(0,0,0,0.3));
  border-radius: 10px;
  margin: 2em auto;
  background: radial-gradient(circle at 0% 0%, white 0%, #e3dcee 100%) ;
  max-width: 50%;
  min-width: 350px;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
}
.searchMenu:hover {
  transform: scale(1.1);
}
.searchMenu:focus-within  {
  transform: scale(1.1);
}

.input {
  width: 300px;
  @media only screen and (max-width: 1000px) {
    width: 150px;
  }
}
.fab {
  margin: 0 2em;
  @media only screen and (max-width: 1000px) {
    margin: 0 0.2em;
  }
}
.sendIcon {
  margin: 0 0.5em;
}
.inputLabel {
  transform: scale(0.7) !important;
  @media only screen and (max-width: 600px) {
    transform: scale(.5) !important;;
  }
}