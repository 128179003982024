body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar_root__6G3Jw {
  width: 100%;
  background: #dddddd;
  background: -webkit-gradient(linear, left top, right top, from(#dddddd), color-stop(20%, #fbfbfb), color-stop(80%, white), to(#dddddd));
  background: -webkit-linear-gradient(left, #dddddd 0%, #fbfbfb 20%, white 80%, #dddddd 100%);
  background: linear-gradient(90deg, #dddddd 0%, #fbfbfb 20%, white 80%, #dddddd 100%);
  padding-bottom: 2em; }

@-webkit-keyframes contact_fadeIn__U7cng {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

.contact_contact__3xbFz {
  padding: 0 2em 2em 2em;
  display: grid;
  justify-content: center;
  align-content: space-between; }

.contact_iconArea__2TwSV {
  display: flex;
  animation-duration: 0.5s;
  /* the duration of the animation */
  animation-timing-function: ease-in-out;
  /* how the animation will behave */
  animation-delay: 0s;
  /* how long to delay the animation from starting */
  animation-iteration-count: 1;
  /* how many times the animation will play */
  animation-name: contact_fadeIn__U7cng;
  /* the name of the animation we defined above */
  -webkit-animation-duration: 0.5s;
  /* the duration of the animation */
  -webkit-animation-timing-function: ease-in-out;
  /* how the animation will behave */
  -webkit-animation-delay: 0s;
  /* how long to delay the animation from starting */
  -webkit-animation-iteration-count: 1;
  /* how many times the animation will play */
  -webkit-animation-name: contact_fadeIn__U7cng;
  /* the name of the animation we defined above */
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.contact_href__3mEl9 {
  margin: 2em; }
  @media only screen and (max-width: 800px) {
    .contact_href__3mEl9 {
      margin: 1em; } }
  @media only screen and (max-width: 600px) {
    .contact_href__3mEl9 {
      margin: 0.5em; } }

.contact_card__2RZ-F, .contact_gmail__CNHA8, .contact_facebook__1B3i5, .contact_github__3HAPi, .contact_linkedin__3rK4f {
  width: 20em;
  height: 12em;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  @media only screen and (max-width: 800px) {
    .contact_card__2RZ-F, .contact_gmail__CNHA8, .contact_facebook__1B3i5, .contact_github__3HAPi, .contact_linkedin__3rK4f {
      width: 14em;
      height: 9em; } }
  @media only screen and (max-width: 600px) {
    .contact_card__2RZ-F, .contact_gmail__CNHA8, .contact_facebook__1B3i5, .contact_github__3HAPi, .contact_linkedin__3rK4f {
      width: 8em;
      height: 5em; } }

.contact_card__2RZ-F:hover, .contact_gmail__CNHA8:hover, .contact_facebook__1B3i5:hover, .contact_github__3HAPi:hover, .contact_linkedin__3rK4f:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2); }

.contact_gmail__CNHA8 {
  background: #D44638; }

.contact_facebook__1B3i5 {
  background: #3a5998; }

.contact_github__3HAPi {
  background: black; }

.contact_linkedin__3rK4f {
  background: #007bb6; }

.contact_image__xnCHi {
  width: 10em;
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1); }
  @media only screen and (max-width: 800px) {
    .contact_image__xnCHi {
      width: 7em; } }
  @media only screen and (max-width: 600px) {
    .contact_image__xnCHi {
      width: 5em; } }

.contact_mapContainer__3lR9w {
  position: relative !important;
  width: 100%;
  height: 50vh !important; }

h3 {
  font-family: nunito;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  color: #3d354b;
  text-align: center; }

.searchMenu_searchMenu__1rYVi {
  position: -webkit-sticky;
  position: sticky;
  z-index: 500;
  top: 90px;
  display: flex;
  align-items: center;
  padding: 1em;
  justify-content: center;
  -webkit-filter: drop-shadow(0px 5px 7px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0px 5px 7px rgba(0, 0, 0, 0.3));
  border-radius: 10px;
  margin: 2em auto;
  background: -webkit-radial-gradient(0% 0%, circle, white 0%, #e3dcee 100%);
  background: radial-gradient(circle at 0% 0%, white 0%, #e3dcee 100%);
  max-width: 50%;
  min-width: 350px;
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.searchMenu_searchMenu__1rYVi:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

.searchMenu_searchMenu__1rYVi:focus-within {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

.searchMenu_input__LoGic {
  width: 300px; }
  @media only screen and (max-width: 1000px) {
    .searchMenu_input__LoGic {
      width: 150px; } }

.searchMenu_fab__2Yh1F {
  margin: 0 2em; }
  @media only screen and (max-width: 1000px) {
    .searchMenu_fab__2Yh1F {
      margin: 0 0.2em; } }

.searchMenu_sendIcon__1cwsD {
  margin: 0 0.5em; }

.searchMenu_inputLabel__1ymQh {
  -webkit-transform: scale(0.7) !important;
          transform: scale(0.7) !important; }
  @media only screen and (max-width: 600px) {
    .searchMenu_inputLabel__1ymQh {
      -webkit-transform: scale(0.5) !important;
              transform: scale(0.5) !important; } }

.portfolio_progress__1a5sG {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11em 0; }

.RepoItem_Summary__DNXWq {
  align-items: center; }

.RepoItem_panel__Acjri {
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out; }

.RepoItem_panel__Acjri:hover {
  background: aliceblue; }

.RepoItem_Summary__DNXWq > div {
  box-sizing: border-box;
  flex-basis: 27%;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center; }
  @media only screen and (max-width: 600px) {
    .RepoItem_Summary__DNXWq > div {
      flex-basis: 40%;
      overflow: visible; } }

.RepoItem_Summary__DNXWq > div:first-child {
  padding-left: 32px; }
  @media only screen and (max-width: 1000px) {
    .RepoItem_Summary__DNXWq > div:first-child {
      padding: 0; } }

@media only screen and (max-width: 1000px) {
  .RepoItem_Summary__DNXWq > div:last-child {
    padding-right: 0; } }

.RepoItem_textField__Zm-Af {
  max-width: 250px;
  text-align: left; }
  @media only screen and (max-width: 1000px) {
    .RepoItem_textField__Zm-Af {
      font-size: 0.7em;
      max-width: 150px; } }

.RepoItem_Tooltip__1wj_v {
  position: relative;
  float: right; }
  @media only screen and (max-width: 1000px) {
    .RepoItem_Tooltip__1wj_v {
      padding: 0; } }

.RepoItem_hide__3lwQg {
  display: flex;
  justify-content: flex-end; }
  @media only screen and (max-width: 1200px) {
    .RepoItem_hide__3lwQg {
      justify-content: center; } }
  @media only screen and (max-width: 600px) {
    .RepoItem_hide__3lwQg {
      display: none; } }

.RepoList_DetailsContainer__O8aMd {
  display: grid;
  width: 100%; }

.RepoList_RefreshButton__2Ku6O {
  justify-self: flex-end;
  display: flex;
  grid-template-columns: auto auto;
  grid-column-gap: 30px;
  margin-right: 32px; }

.RepoList_detailsContent__2a4IF {
  display: flex;
  justify-content: center; }

.RepoList_Progress__35ae9 {
  position: absolute;
  left: 45%; }

@font-face {
  font-family: MuliRegular;
  src: url(/static/media/Muli-Regular.d80f1393.ttf); }

@font-face {
  font-family: nunito;
  src: url(/static/media/Nunito-Regular.dba92cb9.ttf); }

.experience_container__3vnwt {
  margin-top: 2em;
  display: grid;
  justify-content: center; }
  @media only screen and (max-width: 1400px) {
    .experience_container__3vnwt > .experience_subContainer__1azn5 {
      justify-content: center;
      display: grid; } }
  .experience_container__3vnwt > .experience_subContainer2__1M6-C {
    display: flex;
    justify-content: center; }
    @media only screen and (max-width: 900px) {
      .experience_container__3vnwt > .experience_subContainer2__1M6-C {
        display: grid; } }

h3 {
  display: flex;
  justify-content: center;
  color: #3d354b; }

.experience_chip__3DiyT {
  margin: 5px; }

.experience_particles__w45zK {
  background: transparent;
  position: absolute;
  z-index: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.expCard_card__1NEQ- {
  width: 450px;
  margin: 1em;
  position: relative;
  justify-content: center; }
  @media only screen and (max-width: 600px) {
    .expCard_card__1NEQ- {
      width: 90%; } }

.expCard_media__39Rzx {
  width: 65% !important;
  height: 40px !important;
  padding: 2em !important;
  margin: 0 auto !important; }
  @media only screen and (max-width: 600px) {
    .expCard_media__39Rzx {
      height: 35px !important; } }

.expCard_variant__MK1Id {
  width: 50% !important;
  height: 40px !important;
  padding: 2em !important;
  margin: 0 auto !important; }
  @media only screen and (max-width: 600px) {
    .expCard_variant__MK1Id {
      height: 35px !important; } }

.expCard_cardActions__1f3Xa {
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: space-between; }

.expCard_stack__3ij8t {
  padding: 2em 0; }

.expCard_date__3oxgs {
  font-family: "Noto Sans";
  padding-left: 0.5em;
  font-size: 0.8em; }

@font-face {
  font-family: MuliRegular;
  src: url(/static/media/Muli-Regular.d80f1393.ttf); }

@font-face {
  font-family: nunito;
  src: url(/static/media/Nunito-Regular.dba92cb9.ttf); }

.projectCard_card__1GWvV {
  font-family: MuliRegular, serif;
  width: 450px;
  margin: 1em;
  position: relative;
  padding-bottom: 2em; }
  @media only screen and (max-width: 600px) {
    .projectCard_card__1GWvV {
      width: 90%; } }

.projectCard_media__Izidn {
  font-family: MuliRegular, serif;
  width: 200px !important;
  height: 200px !important;
  padding: 1em 0 !important;
  margin: 0 auto; }
  @media only screen and (max-width: 600px) {
    .projectCard_media__Izidn {
      width: 120px !important;
      height: 120px !important; } }

.projectCard_variant__2CxT9 {
  font-family: MuliRegular, serif;
  width: 250px !important;
  height: 150px !important;
  padding: 1em 0 !important;
  margin: 1.5em auto; }
  @media only screen and (max-width: 600px) {
    .projectCard_variant__2CxT9 {
      width: 150px !important;
      height: 100px !important; } }

.projectCard_cardActions__1aQpA {
  position: absolute;
  bottom: 0;
  right: 0; }

.projectCard_typo__1HVFc {
  margin: 1em 0; }

.projectCard_description__X7RzV {
  line-height: 1.3em;
  text-align: center;
  padding: 1em;
  font-size: 1em; }

@-webkit-keyframes slider_slideInFromLeft__2lkFo {
  0%, 40% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: 0; }
  60% {
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; } }

@-webkit-keyframes slider_slideInFromRight__CVMc1 {
  0%, 40% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0; }
  60% {
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; } }

@-webkit-keyframes slider_fadeIn__2x0DU {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

.slider_slider__2l0bv {
  border-radius: 1em;
  margin: 2em 15em;
  width: 65%;
  -webkit-filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
  animation-duration: 1.5s;
  /* the duration of the animation */
  animation-timing-function: ease;
  /* how the animation will behave */
  animation-delay: 0s;
  /* how long to delay the animation from starting */
  animation-iteration-count: 1;
  /* how many times the animation will play */
  -webkit-animation-duration: 1.5s;
  /* the duration of the animation */
  -webkit-animation-timing-function: ease;
  /* how the animation will behave */
  -webkit-animation-delay: 0s;
  /* how long to delay the animation from starting */
  -webkit-animation-iteration-count: 1;
  /* how many times the animation will play */ }
  @media only screen and (max-width: 800px) {
    .slider_slider__2l0bv {
      margin: 2em auto;
      width: 90%; } }
  .slider_slider__2l0bv:nth-of-type(even) {
    background: -webkit-radial-gradient(0% 0%, circle, #d4d3ff 0%, #9f9fd2 100%);
    background: radial-gradient(circle at 0% 0%, #d4d3ff 0%, #9f9fd2 100%);
    margin: 2em 15em;
    animation-name: slider_slideInFromLeft__2lkFo;
    /* the name of the animation we defined above */
    -webkit-animation-name: slider_slideInFromLeft__2lkFo;
    /* the name of the animation we defined above */
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease; }
    @media only screen and (max-width: 800px) {
      .slider_slider__2l0bv:nth-of-type(even) {
        margin: 2em auto;
        width: 90%;
        animation-name: slider_fadeIn__2x0DU;
        /* the name of the animation we defined above */
        -webkit-animation-name: slider_fadeIn__2x0DU;
        /* the name of the animation we defined above */ } }
  .slider_slider__2l0bv:nth-of-type(odd) {
    background: -webkit-radial-gradient(100% 0%, circle, #d4d3ff 0%, #9f9fd2 100%);
    background: radial-gradient(circle at 100% 0%, #d4d3ff 0%, #9f9fd2 100%);
    margin: 0 15em 0 auto;
    animation-name: slider_slideInFromRight__CVMc1;
    /* the name of the animation we defined above */
    -webkit-animation-name: slider_slideInFromRight__CVMc1;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease; }
    @media only screen and (max-width: 800px) {
      .slider_slider__2l0bv:nth-of-type(odd) {
        margin: 2em auto;
        width: 90%;
        animation-name: slider_fadeIn__2x0DU;
        /* the name of the animation we defined above */
        -webkit-animation-name: slider_fadeIn__2x0DU;
        /* the name of the animation we defined above */ } }
  .slider_slider__2l0bv:nth-child(1) {
    margin-top: 2em; }
  .slider_slider__2l0bv:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  .slider_slider__2l0bv > .slider_header__2poh2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .slider_slider__2l0bv > .slider_header__2poh2 > .slider_typo__4bCSq {
      width: 50%;
      padding: 1em;
      background: #917e9a; }
      @media only screen and (max-width: 800px) {
        .slider_slider__2l0bv > .slider_header__2poh2 > .slider_typo__4bCSq {
          padding: 0.5em; } }
    .slider_slider__2l0bv > .slider_header__2poh2 > .slider_icon__J2O22 {
      padding: 0.2em 0.5em;
      cursor: pointer;
      color: white;
      -webkit-transition: all 0.5s;
      transition: all 0.5s; }
      .slider_slider__2l0bv > .slider_header__2poh2 > .slider_icon__J2O22:hover {
        color: red;
        -webkit-transform: scale(1.1);
                transform: scale(1.1); }

.slider_logo__JpQ4m {
  width: 5em;
  height: 5em;
  -webkit-filter: drop-shadow(0px 1px 1px black);
          filter: drop-shadow(0px 1px 1px black); }
  @media only screen and (max-width: 1300px) {
    .slider_logo__JpQ4m {
      width: 3em;
      height: 3em; } }
  @media only screen and (max-width: 800px) {
    .slider_logo__JpQ4m {
      width: 2em;
      height: 2em; } }

.slider_container__3EHn2 {
  margin: 0 auto;
  padding: 2em;
  width: 75%; }
  @media only screen and (max-width: 800px) {
    .slider_container__3EHn2 {
      padding: 1em 2em 2em 2em;
      width: 80%; } }

h2 {
  margin: 0;
  text-align: center;
  color: white;
  font-weight: 400; }
  @media only screen and (max-width: 800px) {
    h2 {
      font-size: 1em; } }

@font-face {
  font-family: MuliRegular;
  src: url(/static/media/Muli-Regular.d80f1393.ttf); }

@font-face {
  font-family: nunito;
  src: url(/static/media/Nunito-Regular.dba92cb9.ttf); }

@-webkit-keyframes header_rollIn__22SOY {
  0%, 50% {
    -webkit-transform: translateX(-100%) rotate(-250deg);
            transform: translateX(-100%) rotate(-250deg);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0) rotate(0);
            transform: translateX(0) rotate(0);
    opacity: 1; } }

@-webkit-keyframes header_fadeIn__8wz9j {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

.header_header__1lhGh {
  display: flex;
  z-index: 2;
  position: relative;
  align-items: center;
  background: url(/static/media/background.a06e50c7.jpg) no-repeat right;
  background-size: cover;
  height: 24em; }
  @media only screen and (max-width: 1500px) {
    .header_header__1lhGh {
      height: 20em; } }
  @media only screen and (max-width: 1280px) {
    .header_header__1lhGh {
      height: 17em; } }
  @media only screen and (max-width: 950px) {
    .header_header__1lhGh {
      height: 14em; } }
  @media only screen and (max-width: 800px) {
    .header_header__1lhGh {
      height: 13em; } }
  @media only screen and (max-width: 600px) {
    .header_header__1lhGh {
      height: 10em; } }
  @media only screen and (max-width: 450px) {
    .header_header__1lhGh {
      height: 7em; } }

.header_avatar__191be {
  margin: 2em 5em 2em 23em;
  width: 230px;
  height: 230px;
  z-index: 5;
  border-radius: 50%;
  background: url(/static/media/IMG_4837.9f5ed249.JPG) no-repeat center;
  background-size: cover;
  animation-duration: 2s;
  /* the duration of the animation */
  animation-timing-function: ease;
  /* how the animation will behave */
  animation-delay: 0s;
  /* how long to delay the animation from starting */
  animation-iteration-count: 1;
  /* how many times the animation will play */
  animation-name: header_rollIn__22SOY;
  /* the name of the animation we defined above */
  -webkit-animation-duration: 2s;
  /* the duration of the animation */
  -webkit-animation-timing-function: ease;
  /* how the animation will behave */
  -webkit-animation-delay: 0s;
  /* how long to delay the animation from starting */
  -webkit-animation-iteration-count: 1;
  /* how many times the animation will play */
  -webkit-animation-name: header_rollIn__22SOY;
  /* the name of the animation we defined above */
  -webkit-transition: all .5s;
  transition: all .5s; }
  @media only screen and (max-width: 1500px) {
    .header_avatar__191be {
      margin: 2em 5em 2em 15em;
      width: 180px;
      height: 180px; } }
  @media only screen and (max-width: 1280px) {
    .header_avatar__191be {
      margin: 2em 4em 2em 9em;
      width: 150px;
      height: 150px; } }
  @media only screen and (max-width: 800px) {
    .header_avatar__191be {
      margin: 2em 2em 2em 4em;
      width: 120px;
      height: 120px; } }
  @media only screen and (max-width: 600px) {
    .header_avatar__191be {
      margin: 2em 1em 2em 1em;
      width: 90px;
      height: 90px; } }
  @media only screen and (max-width: 450px) {
    .header_avatar__191be {
      margin: 2em 0.5em 2em 0.7em;
      width: 60px;
      height: 60px; } }

.header_avatar__191be:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

.header_animation__11zhL, .header_underName__VUpdL {
  animation-duration: 1s;
  /* the duration of the animation */
  animation-timing-function: ease;
  /* how the animation will behave */
  animation-delay: 0s;
  /* how long to delay the animation from starting */
  animation-iteration-count: 1;
  /* how many times the animation will play */
  animation-name: header_fadeIn__8wz9j;
  /* the name of the animation we defined above */
  -webkit-animation-duration: 1s;
  /* the duration of the animation */
  -webkit-animation-timing-function: ease;
  /* how the animation will behave */
  -webkit-animation-delay: 0s;
  /* how long to delay the animation from starting */
  -webkit-animation-iteration-count: 1;
  /* how many times the animation will play */
  -webkit-animation-name: header_fadeIn__8wz9j;
  /* the name of the animation we defined above */ }

@-webkit-keyframes header_typing__16zt_ {
  from {
    width: 0; }
  to {
    width: 100%; } }

@keyframes header_typing__16zt_ {
  from {
    width: 0; }
  to {
    width: 100%; } }

/* The typewriter cursor effect */
@-webkit-keyframes header_blink-caret__2XW0G {
  from, to {
    border-color: transparent; }
  50% {
    border-color: black; } }
@keyframes header_blink-caret__2XW0G {
  from, to {
    border-color: transparent; }
  50% {
    border-color: black; } }

.header_name__2gUzQ {
  z-index: 5;
  font-size: 3em;
  padding: 0.2em 0.1em 0.2em 0;
  white-space: nowrap;
  color: #3d354b;
  font-family: MuliRegular, serif;
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  border-right: .07em solid black;
  /* The typwriter cursor */
  margin: 0 auto;
  /* Gives that scrolling effect as the typing happens */
  -webkit-animation: header_typing__16zt_ 2s steps(25, end), header_blink-caret__2XW0G 0.75s step-end infinite;
          animation: header_typing__16zt_ 2s steps(25, end), header_blink-caret__2XW0G 0.75s step-end infinite; }
  @media only screen and (max-width: 1500px) {
    .header_name__2gUzQ {
      font-size: 2.5em; } }
  @media only screen and (max-width: 1280px) {
    .header_name__2gUzQ {
      font-size: 2em; } }
  @media only screen and (max-width: 800px) {
    .header_name__2gUzQ {
      font-size: 1.8em;
      padding: 0.1em 0.1em 0.1em 0; } }
  @media only screen and (max-width: 600px) {
    .header_name__2gUzQ {
      font-size: 1.5em;
      padding: 0.05em 0.05em 0.05em 0; } }
  @media only screen and (max-width: 450px) {
    .header_name__2gUzQ {
      font-size: 1.1em; } }

.header_underName__VUpdL {
  z-index: 5;
  padding: 0.2em 0;
  white-space: nowrap;
  color: #3d354b;
  font-family: MuliRegular, serif;
  font-size: 1.5em; }
  @media only screen and (max-width: 1500px) {
    .header_underName__VUpdL {
      font-size: 1.2em; } }
  @media only screen and (max-width: 1280px) {
    .header_underName__VUpdL {
      font-size: 1em; } }
  @media only screen and (max-width: 800px) {
    .header_underName__VUpdL {
      font-size: 0.9em; } }
  @media only screen and (max-width: 600px) {
    .header_underName__VUpdL {
      font-size: 0.7em; } }
  @media only screen and (max-width: 450px) {
    .header_underName__VUpdL {
      font-size: 0.55em; } }

.header_app__s9FFg {
  position: relative; }
  .header_app__s9FFg > .header_blur__1dyvI {
    filter: blur(5px);
    -webkit-filter: blur(5px); }
  .header_app__s9FFg > .header_container__10NS- > .header_switchContainer__3Nxpt {
    z-index: 100;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.7em;
    margin: 1em;
    top: 0;
    left: 0; }
    @media only screen and (max-width: 1500px) {
      .header_app__s9FFg > .header_container__10NS- > .header_switchContainer__3Nxpt {
        left: auto;
        right: 0; } }
    @media only screen and (max-width: 1280px) {
      .header_app__s9FFg > .header_container__10NS- > .header_switchContainer__3Nxpt {
        margin: 0.7em; } }
    @media only screen and (max-width: 600px) {
      .header_app__s9FFg > .header_container__10NS- > .header_switchContainer__3Nxpt {
        margin: 0.1em;
        padding: 0.1em; } }
    .header_app__s9FFg > .header_container__10NS- > .header_switchContainer__3Nxpt > .header_switch__3bWEV {
      padding: 0 1em; }
      @media only screen and (max-width: 1280px) {
        .header_app__s9FFg > .header_container__10NS- > .header_switchContainer__3Nxpt > .header_switch__3bWEV {
          padding: 0 0.4em;
          -webkit-transform: scale(0.8);
                  transform: scale(0.8); } }
      @media only screen and (max-width: 600px) {
        .header_app__s9FFg > .header_container__10NS- > .header_switchContainer__3Nxpt > .header_switch__3bWEV {
          padding: 0;
          -webkit-transform: scale(0.6);
                  transform: scale(0.6); } }
    .header_app__s9FFg > .header_container__10NS- > .header_switchContainer__3Nxpt > .header_lang__2G7BY {
      width: 2.5em;
      height: auto; }
      @media only screen and (max-width: 1280px) {
        .header_app__s9FFg > .header_container__10NS- > .header_switchContainer__3Nxpt > .header_lang__2G7BY {
          width: 2em; } }
      @media only screen and (max-width: 600px) {
        .header_app__s9FFg > .header_container__10NS- > .header_switchContainer__3Nxpt > .header_lang__2G7BY {
          width: 1.5em; } }

.header_uncheckedSwitch__3q1MM, .header_checkedSwitch__3fIFg {
  width: 1.8em; }

.header_checkedSwitch__3fIFg {
  margin-left: 0.8em; }

.header_particles__3x9S2 {
  background: transparent;
  position: absolute;
  z-index: 3;
  width: 100%;
  top: 0;
  height: 24em; }
  @media only screen and (max-width: 1500px) {
    .header_particles__3x9S2 {
      height: 20em; } }
  @media only screen and (max-width: 1280px) {
    .header_particles__3x9S2 {
      height: 17em; } }
  @media only screen and (max-width: 950px) {
    .header_particles__3x9S2 {
      height: 14em; } }
  @media only screen and (max-width: 800px) {
    .header_particles__3x9S2 {
      height: 13em; } }
  @media only screen and (max-width: 600px) {
    .header_particles__3x9S2 {
      height: 10em; } }
  @media only screen and (max-width: 450px) {
    .header_particles__3x9S2 {
      height: 7em; } }

.footer_footer__3gZTc {
  font-size: 1em;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-filter: drop-shadow(0px -2px 2px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0px -2px 2px rgba(0, 0, 0, 0.3));
  background: #eeeeee; }
  @media only screen and (max-width: 600px) {
    .footer_footer__3gZTc {
      font-size: 0.6em; } }

.cookies_container__1I65Y {
  position: fixed;
  z-index: 10000;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6); }
  .cookies_container__1I65Y > .cookies_content__2YQ3e {
    font-size: 0.85em;
    padding: 1em;
    border-radius: 1em;
    display: block;
    position: relative;
    text-align: center;
    justify-content: center;
    align-items: center;
    background: #eeeeee;
    width: 40%;
    height: auto; }
    @media only screen and (max-width: 1280px) {
      .cookies_container__1I65Y > .cookies_content__2YQ3e {
        width: 55%; } }
    @media only screen and (max-width: 600px) {
      .cookies_container__1I65Y > .cookies_content__2YQ3e {
        width: 80%; } }
    .cookies_container__1I65Y > .cookies_content__2YQ3e > .cookies_cancel__2owLM {
      position: absolute;
      right: 0;
      top: 0;
      margin: 1em;
      cursor: pointer; }
    .cookies_container__1I65Y > .cookies_content__2YQ3e > .cookies_title__1Gk_4 {
      font-size: 1.5em;
      padding: 2em; }
    .cookies_container__1I65Y > .cookies_content__2YQ3e > .cookies_buttonContainer__3m-qK {
      display: flex;
      justify-content: center;
      align-content: center;
      padding: 2em 0; }
      .cookies_container__1I65Y > .cookies_content__2YQ3e > .cookies_buttonContainer__3m-qK > .cookies_button__1OTF2, .cookies_container__1I65Y > .cookies_content__2YQ3e > .cookies_buttonContainer__3m-qK > .cookies_buttonCancel__2QyKg {
        margin: 1em 2em;
        border: 2px solid #8f0b98;
        background: transparent;
        color: #8f0b98;
        width: 8em;
        height: 4em;
        cursor: pointer;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        text-decoration: none; }
        .cookies_container__1I65Y > .cookies_content__2YQ3e > .cookies_buttonContainer__3m-qK > .cookies_button__1OTF2:hover, .cookies_container__1I65Y > .cookies_content__2YQ3e > .cookies_buttonContainer__3m-qK > .cookies_buttonCancel__2QyKg:hover {
          color: white;
          background: #8f0b98; }
      .cookies_container__1I65Y > .cookies_content__2YQ3e > .cookies_buttonContainer__3m-qK > .cookies_buttonCancel__2QyKg {
        background: #8f0b98;
        color: white; }
        .cookies_container__1I65Y > .cookies_content__2YQ3e > .cookies_buttonContainer__3m-qK > .cookies_buttonCancel__2QyKg:hover {
          background: transparent;
          color: #8f0b98; }

.cookies_hide__2lxmZ {
  display: none; }

.cookies_href__3yVHn {
  text-decoration: none;
  color: black; }
  .cookies_href__3yVHn:hover {
    color: blue; }

@-webkit-keyframes expModal_fadeIn__3H61H {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes expModal_fade__3UHlN {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.expModal_container__3RY7_ {
  position: fixed;
  z-index: 10000;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  animation-duration: 0.5s;
  /* the duration of the animation */
  animation-timing-function: ease;
  /* how the animation will behave */
  animation-delay: 0s;
  /* how long to delay the animation from starting */
  animation-iteration-count: 1;
  /* how many times the animation will play */
  -webkit-animation-duration: 0.5s;
  /* the duration of the animation */
  -webkit-animation-timing-function: ease;
  /* how the animation will behave */
  -webkit-animation-delay: 0s;
  /* how long to delay the animation from starting */
  -webkit-animation-iteration-count: 1;
  /* how many times the animation will play */
  animation-name: expModal_fade__3UHlN;
  /* the name of the animation we defined above */
  -webkit-animation-name: expModal_fade__3UHlN;
  /* the name of the animation we defined above */ }
  .expModal_container__3RY7_ > .expModal_modal__2N7ww {
    font-size: 0.85em;
    padding: 1em;
    border-radius: 1em;
    display: block;
    position: relative;
    text-align: center;
    justify-content: center;
    align-items: center;
    background: #eeeeee;
    width: 50%;
    height: auto;
    -webkit-filter: drop-shadow(0px 5px 10px black);
            filter: drop-shadow(0px 5px 10px black);
    animation-duration: 0.5s;
    /* the duration of the animation */
    animation-timing-function: ease;
    /* how the animation will behave */
    animation-delay: 0s;
    /* how long to delay the animation from starting */
    animation-iteration-count: 1;
    /* how many times the animation will play */
    -webkit-animation-duration: 0.5s;
    /* the duration of the animation */
    -webkit-animation-timing-function: ease;
    /* how the animation will behave */
    -webkit-animation-delay: 0s;
    /* how long to delay the animation from starting */
    -webkit-animation-iteration-count: 1;
    /* how many times the animation will play */
    animation-name: expModal_fadeIn__3H61H;
    /* the name of the animation we defined above */
    -webkit-animation-name: expModal_fadeIn__3H61H;
    /* the name of the animation we defined above */ }
    @media only screen and (max-width: 1280px) {
      .expModal_container__3RY7_ > .expModal_modal__2N7ww {
        width: 65%; } }
    @media only screen and (max-width: 600px) {
      .expModal_container__3RY7_ > .expModal_modal__2N7ww {
        width: 80%; } }

.expModal_logo__2eSIG {
  width: 5em;
  height: 5em; }
  @media only screen and (max-width: 1300px) {
    .expModal_logo__2eSIG {
      width: 3em;
      height: 3em; } }
  @media only screen and (max-width: 800px) {
    .expModal_logo__2eSIG {
      width: 2em;
      height: 2em; } }

.expModal_hide__3C5gk {
  display: none; }

.expModal_button__30cfJ {
  margin: 1em 2em;
  border: 2px solid #8f0b98;
  background: transparent;
  color: #8f0b98;
  width: 8em;
  height: 4em;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  text-decoration: none; }
  .expModal_button__30cfJ:hover {
    color: white;
    background: #8f0b98; }

.expModal_row__3KJLw {
  display: flex;
  flex-direction: row;
  padding: 1em;
  align-items: center; }
  @media only screen and (max-width: 800px) {
    .expModal_row__3KJLw {
      padding: 0.4em; } }
  .expModal_row__3KJLw > .expModal_level__1NjsK {
    padding: 0 1em;
    flex-grow: 1; }
    .expModal_row__3KJLw > .expModal_level__1NjsK > div {
      padding: 1em; }
      @media only screen and (max-width: 800px) {
        .expModal_row__3KJLw > .expModal_level__1NjsK > div {
          padding: 0.1em; } }

