@font-face {
  font-family: MuliRegular;
  src: url(../../assets/fonts/Muli-Regular.ttf);
}

@font-face {
  font-family: nunito;
  src: url(../../assets/fonts/Nunito-Regular.ttf);
}

@-webkit-keyframes rollIn {
  0% , 50%{
    transform: translateX(-100%) rotate(-250deg);
    opacity: 0;
  }
  100% {
    transform: translateX(0) rotate(0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.header {
  display: flex;
  z-index: 2;
  position: relative;
  align-items: center;
  background: url("../../assets/images/background.jpg") no-repeat right;
  background-size: cover;
  height: 24em;
  @media only screen and (max-width: 1500px) {
    height: 20em;
  }
  @media only screen and (max-width: 1280px) {
    height: 17em;
  }
  @media only screen and (max-width: 950px) {
    height: 14em;
  }
  @media only screen and (max-width: 800px) {
    height: 13em;
  }
  @media only screen and (max-width: 600px) {
    height: 10em;
  }
  @media only screen and (max-width: 450px) {
    height: 7em;
  }
}

.avatar {
  margin: 2em 5em 2em 23em;
  width: 230px;
  height: 230px;
  z-index: 5;
  border-radius: 50%;
  background: url("../../assets/images/IMG_4837.JPG") no-repeat center;
  background-size: cover;
  @media only screen and (max-width: 1500px) {
    margin: 2em 5em 2em 15em;
    width: 180px;
    height: 180px;
  }
  @media only screen and (max-width: 1280px) {
    margin: 2em 4em 2em 9em;
    width: 150px;
    height: 150px;
  }
  @media only screen and (max-width: 800px) {
    margin: 2em 2em 2em 4em;
    width: 120px;
    height: 120px;
  }
  @media only screen and (max-width: 600px) {
    margin: 2em 1em 2em 1em;
    width: 90px;
    height: 90px;
  }
  @media only screen and (max-width: 450px) {
    margin: 2em 0.5em 2em 0.7em;
    width: 60px;
    height: 60px;
  }
  animation-duration: 2s; /* the duration of the animation */
  animation-timing-function: ease; /* how the animation will behave */
  animation-delay: 0s; /* how long to delay the animation from starting */
  animation-iteration-count: 1; /* how many times the animation will play */
  animation-name: rollIn; /* the name of the animation we defined above */
  -webkit-animation-duration: 2s; /* the duration of the animation */
  -webkit-animation-timing-function: ease; /* how the animation will behave */
  -webkit-animation-delay: 0s; /* how long to delay the animation from starting */
  -webkit-animation-iteration-count: 1; /* how many times the animation will play */
  -webkit-animation-name: rollIn; /* the name of the animation we defined above */
  transition: all .5s;
}

.avatar:hover {
  transform: scale(1.1);
}

.animation {
  animation-duration: 1s; /* the duration of the animation */
  animation-timing-function: ease; /* how the animation will behave */
  animation-delay: 0s; /* how long to delay the animation from starting */
  animation-iteration-count: 1; /* how many times the animation will play */
  animation-name: fadeIn; /* the name of the animation we defined above */
  -webkit-animation-duration: 1s; /* the duration of the animation */
  -webkit-animation-timing-function: ease; /* how the animation will behave */
  -webkit-animation-delay: 0s; /* how long to delay the animation from starting */
  -webkit-animation-iteration-count: 1; /* how many times the animation will play */
  -webkit-animation-name: fadeIn; /* the name of the animation we defined above */
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: black; }
}

.name {
  z-index: 5;
  font-size: 3em;
  padding: 0.2em 0.1em 0.2em 0;
  white-space: nowrap;
  color: #3d354b;
  font-family: MuliRegular, serif;
  @media only screen and (max-width: 1500px) {
    font-size: 2.5em;
  }
  @media only screen and (max-width: 1280px) {
    font-size: 2em;
  }
  @media only screen and (max-width: 800px) {
    font-size: 1.8em;
    padding: 0.1em 0.1em 0.1em 0;
  }
  @media only screen and (max-width: 600px) {
    font-size: 1.5em;
    padding: 0.05em 0.05em 0.05em 0;
  }
  @media only screen and (max-width: 450px) {
    font-size: 1.1em;
  }
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .07em solid black; /* The typwriter cursor */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  animation:
          typing 2s steps(25, end),
          blink-caret .75s step-end infinite;
}

.underName {
  z-index: 5;
  @extend .animation;
  padding: 0.2em 0;
  white-space: nowrap;
  color: #3d354b;
  font-family: MuliRegular, serif;;
  font-size: 1.5em;
  @media only screen and (max-width: 1500px) {
    font-size: 1.2em;
  }
  @media only screen and (max-width: 1280px) {
    font-size: 1em;
  }
  @media only screen and (max-width: 800px) {
    font-size: 0.9em;
  }
  @media only screen and (max-width: 600px) {
    font-size: 0.7em;
  }
  @media only screen and (max-width: 450px) {
    font-size: 0.55em;
  }
  @extend .animation;
}

.app {
  position: relative;
  > .blur {
    filter: blur(5px);
    -webkit-filter: blur(5px);
  }
  > .container {
    > .switchContainer {
      z-index: 100;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.7em;
      margin: 1em;
      top: 0;
      left: 0;
      @media only screen and (max-width: 1500px) {
        left: auto;
        right: 0;
      }
      @media only screen and (max-width: 1280px) {
        margin: 0.7em;
      }
      @media only screen and (max-width: 600px) {
        margin: 0.1em;
        padding: 0.1em;
      }
      > .switch {
        padding: 0 1em;
        @media only screen and (max-width: 1280px) {
          padding: 0 0.4em;
          transform: scale(0.8);
        }
        @media only screen and (max-width: 600px) {
          padding: 0;
          transform: scale(0.6);
        }
      }
      > .lang {
        width: 2.5em;
        height: auto;
        @media only screen and (max-width: 1280px) {
          width: 2em;
        }
        @media only screen and (max-width: 600px) {
          width: 1.5em;
        }
      }
    }
  }
}
.uncheckedSwitch {
  width: 1.8em;
}

.checkedSwitch {
  @extend .uncheckedSwitch;
  margin-left: 0.8em;
}

.particles {
  background: transparent;
  position: absolute;
  z-index: 3;
  width: 100%;
  top: 0;
  height: 24em;
  @media only screen and (max-width: 1500px) {
    height: 20em;
  }
  @media only screen and (max-width: 1280px) {
    height: 17em;
  }
  @media only screen and (max-width: 950px) {
    height: 14em;
  }
  @media only screen and (max-width: 800px) {
    height: 13em;
  }
  @media only screen and (max-width: 600px) {
    height: 10em;
  }
  @media only screen and (max-width: 450px) {
    height: 7em;
  }
}


