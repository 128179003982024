@-webkit-keyframes slideInFromLeft {
  0%, 40%{
    transform: translateX(-100%);
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slideInFromRight {
  0%, 40% {
    transform: translateX(100%);
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.slider {
  border-radius: 1em;
  margin: 2em 15em;
  width: 65%;
  filter: drop-shadow(0px 3px 3px rgba(0,0,0,0.4));
  @media only screen and (max-width: 800px) {
    margin: 2em auto;
    width: 90%;
  }
  animation-duration: 1.5s; /* the duration of the animation */
  animation-timing-function: ease; /* how the animation will behave */
  animation-delay: 0s; /* how long to delay the animation from starting */
  animation-iteration-count: 1; /* how many times the animation will play */
  -webkit-animation-duration: 1.5s; /* the duration of the animation */
  -webkit-animation-timing-function: ease; /* how the animation will behave */
  -webkit-animation-delay: 0s; /* how long to delay the animation from starting */
  -webkit-animation-iteration-count: 1; /* how many times the animation will play */
  &:nth-of-type(even) {
    background: radial-gradient(circle at 0% 0%, rgb(212, 211, 255) 0%, #9f9fd2 100%) ;
    margin: 2em 15em;
    animation-name: slideInFromLeft; /* the name of the animation we defined above */
    -webkit-animation-name: slideInFromLeft; /* the name of the animation we defined above */
    transition: all 0.2s ease ;
    @media only screen and (max-width: 800px) {
      margin: 2em auto;
      width: 90%;
      animation-name: fadeIn; /* the name of the animation we defined above */
      -webkit-animation-name: fadeIn; /* the name of the animation we defined above */
    }
  }
  &:nth-of-type(odd) {
    background: radial-gradient(circle at 100% 0%, rgb(212, 211, 255) 0%, #9f9fd2 100%) ;
    margin: 0 15em 0 auto;
    animation-name: slideInFromRight; /* the name of the animation we defined above */
    -webkit-animation-name: slideInFromRight;
    transition: all 0.2s ease;
    @media only screen and (max-width: 800px) {
      margin: 2em auto;
      width: 90%;
      animation-name: fadeIn; /* the name of the animation we defined above */
      -webkit-animation-name: fadeIn; /* the name of the animation we defined above */
    }
  }
  &:nth-child(1){
    margin-top: 2em;
  }

  &:hover {
    transform: scale(1.05);
  }
  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > .typo {
      width: 50%;
      padding: 1em;
      background: #917e9a;
      @media only screen and (max-width: 800px) {
        padding: 0.5em;
      }
    }
    > .icon {
      padding: 0.2em 0.5em;
      cursor: pointer;
      color: white;
      transition: all 0.5s;
      &:hover {
        color: red;
        transform: scale(1.1);
      }
    }
  }
}

.logo {
  width: 5em;
  height: 5em;
  filter: drop-shadow(0px 1px 1px black);
  @media only screen and (max-width: 1300px) {
    width: 3em;
    height: 3em;
  }
  @media only screen and (max-width: 800px) {
    width: 2em;
    height: 2em;
  }
}

.container {
  margin: 0 auto;
  padding: 2em;
  width: 75%;
  @media only screen and (max-width: 800px) {
    padding: 1em 2em 2em 2em;
    width: 80%;
  }
}

h2 {
  margin: 0;
  text-align: center;
  color: white;
  font-weight: 400;
  @media only screen and (max-width: 800px) {
    font-size: 1em;
  }
}

