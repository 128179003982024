@font-face {
  font-family: MuliRegular;
  src: url(../../assets/fonts/Muli-Regular.ttf);
}

@font-face {
  font-family: nunito;
  src: url(../../assets/fonts/Nunito-Regular.ttf);
}

.container {
  margin-top: 2em;
  display: grid;
  justify-content: center;
  >.subContainer {
    @media only screen and (max-width: 1400px) {
      justify-content: center;
      display: grid;
    }
  }

  >.subContainer2 {
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 900px) {
      display: grid;
    }
  }
}

h3 {
  display: flex;
  justify-content: center;
  color: #3d354b
}

.chip {
  margin: 5px;
}

.particles {
  background: transparent;
  position: absolute;
  z-index: 0;
  top: 0;
  width: 100%;
  height: 100%;
}