.DetailsContainer {
  display: grid;
  width: 100%;
}

.RefreshButton {
  justify-self: flex-end;
  display: flex;
  grid-template-columns: auto auto;
  grid-column-gap: 30px;
  margin-right: 32px;
}

.detailsContent {
  display: flex;
  justify-content: center;
}

.Progress {
  position: absolute;
  left: 45%;
}