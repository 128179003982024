@-webkit-keyframes fadeIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.contact {
  padding: 0 2em 2em 2em;
  display: grid;
  justify-content: center;
  align-content: space-between;
}

.iconArea {
  display: flex;
  animation-duration: 0.5s; /* the duration of the animation */
  animation-timing-function: ease-in-out; /* how the animation will behave */
  animation-delay: 0s; /* how long to delay the animation from starting */
  animation-iteration-count: 1; /* how many times the animation will play */
  animation-name: fadeIn; /* the name of the animation we defined above */
  -webkit-animation-duration: 0.5s; /* the duration of the animation */
  -webkit-animation-timing-function: ease-in-out; /* how the animation will behave */
  -webkit-animation-delay: 0s; /* how long to delay the animation from starting */
  -webkit-animation-iteration-count: 1; /* how many times the animation will play */
  -webkit-animation-name: fadeIn; /* the name of the animation we defined above */
  transition: all 0.2s ease-in-out ;
}

.href {
  margin: 2em;
  @media only screen and (max-width: 800px) {
   margin: 1em;
  }
  @media only screen and (max-width: 600px) {
    margin: 0.5em;;
  }
}

.card {
  width: 20em;
  height: 12em;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 800px) {
    width: 14em;
    height: 9em;
  }
  @media only screen and (max-width: 600px) {
    width: 8em;
    height: 5em;
  }
  transition: all 0.2s ease-in-out ;
}
.card:hover {
  transform: scale(1.2);
}
.gmail {
  @extend .card;
  background: #D44638;
}

.facebook {
  @extend .card;
  background: #3a5998;
}

.github {
  @extend .card;
  background: black;
}

.linkedin {
  @extend .card;
  background: #007bb6;
}

.image {
  width: 10em;
  filter: brightness(0) invert(1);
  @media only screen and (max-width: 800px) {
    width: 7em;
  }
  @media only screen and (max-width: 600px) {
    width: 5em;
  }
}

.mapContainer {
  position: relative !important;
  width: 100%;
  height: 50vh !important;
}

h3 {
  font-family: nunito;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  color: #3d354b;
  text-align: center;
}