.footer {
  font-size: 1em;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px -2px 2px rgba(0,0,0,0.3));
  background: #eeeeee;
  @media only screen and (max-width: 600px) {
    font-size: 0.6em;
  }
}